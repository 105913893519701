<template>
  <b-sidebar no-header v-model="show" backdrop shadow right class="sidebar-edit-proposal" @change="hasClosedSidebar" ref="sidebar_edit_proposal">
    <div class="p-1">
      <div class="d-flex justify-content-end">
        <feather-icon 
          icon="XIcon" 
          size="20" 
          class="close-sidebar-icon"
          @click="show = false"
        />
      </div>
    </div>

    <samples v-if="section === 'samples'" :is_editing="true" @update_proposal="updateProposal"/>
    <category-subcategory 
      v-else-if="section === 'categories'" 
      @update_proposal="updateProposal"
      @change_view_connection="changeViewConnection"
    />
    <enrichment-connection v-else-if="section === 'connection'" @reset_proposal="resetProposal"/>
    
    <title-description v-else-if="section === 'title_description'" @update_proposal="updateProposal"/>
    <deals-component 
      v-if="section === 'deals'" 
      :is_editing="Object.keys(data_for_sidebar).length > 0" 
      :deal_selected="data_for_sidebar" 
      @update_proposal="updateProposal"
    />
    <options-component 
      v-if="section === 'options'" 
      :is_editing="Object.keys(data_for_sidebar).length > 0" 
      :option_selected="data_for_sidebar" 
      @update_proposal="updateProposal"
    />
    <requests-component 
      v-if="section === 'requests'" 
      :is_editing="Object.keys(data_for_sidebar).length > 0" 
      :request_selected="data_for_sidebar" 
      @update_proposal="updateProposal"
    />
  </b-sidebar>
</template>

<script>
import { 
  BSidebar,
} from 'bootstrap-vue';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  name: 'sidebarEditProposal',
  components: {
    BSidebar,
    samples: () => import('./samples.vue'),
    categorySubcategory: () => import('./categorySubcategory.vue'),
    titleDescription: () => import('./titleDescription.vue'),
    dealsComponent: () => import('./dealsComponent.vue'),
    optionsComponent: () => import('./optionsComponent.vue'),
    requestsComponent: () => import('./requestsComponent.vue'),
    enrichmentConnection: () => import('./enrichmentConnection.vue')
  },
  data() {
    return {
      show: this.open_sidebar_edit,
      section: this.section_dad
    }
  },
  props: {
    open_sidebar_edit: {
      type: Boolean,
      default: false,
    },
    proposal: {
      type: Object,
      default: () => {
        return {}
      }
    },
    section_dad: {
      type: String,
    },
    data_for_sidebar: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  methods: {
    hasClosedSidebar() {
      this.$refs.sidebar_edit_proposal.$el.classList.remove('open-sidebar-edit-proposal')
      if (localStorage.getItem('steps')) localStorage.removeItem('steps');
    },
    updateProposal(proposal, close_sidebar = true) {
      this.$emit('update_proposal', proposal);
      if (close_sidebar) this.show = false;
      loadToastificationContent().then((module) => {
        this.$toast({
          component: module.default,
          position: 'top-right',
          props: {
            title: this.$t('creator.proposalUpdated'),
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: this.$t('creator.proposalUpdatedText')
          }
        })
      })
    },
    changeViewConnection() {
      this.section = 'connection';
    },
    resetProposal(proposal) {
      this.$emit('reset_proposal', proposal)
      this.show = false;
    },
  },
}

</script>

<style scoped>
.close-sidebar-icon:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
</style>
<style>
.sidebar-edit-proposal > .b-sidebar {
  width: 950px !important;
}
</style>